import { useMatches } from '@remix-run/react';
import type { SerializeFrom } from '@vercel/remix';

import type { loader } from '~/routes/_auth/_layout.server';

/**
 * This hook allows you to get the authenticated user data from the authenticated base root.
 * calling it from a route that is not authenticated will return null.
 */
export const useAuthenticatedUserData = () => {
  const data = useMatches().find((match) => match.id === 'routes/_auth/_layout')?.data as
    | SerializeFrom<typeof loader>
    | undefined;

  return {
    user: data?.user ?? null,
    token: data?.token ?? null,
    organisation: data?.organisation ?? null,
  };
};
